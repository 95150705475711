<template>
  <div style="background: var(--v-component-base) !important">
    <v-card flat>
      <v-card-text>
        <v-row align="center" justify="end">
          <v-col cols="12" sm="6" md="4">
            <el-input ref="searchBar" suffix-icon="el-icon-search" v-model="params.search" clearable placeholder="Search">
              <template slot="prepend">
                <el-button @click="addRegime"><i class="el-icon-plus"></i></el-button>
              </template>
              <template slot="append">
                <v-badge :key="updateBadge" :content="activeFilters()" :value="activeFilters() > 0" color="primary"
                  offset-y="10">
                  <el-button @click="filterDialog = true" el-tooltip="Advanced Filter">
                    <v-icon small>filter_alt</v-icon>
                  </el-button>
                </v-badge>
              </template>
            </el-input>
          </v-col>
        </v-row>
        <v-data-table class="mt-3" dense disable-pagination fixed-header hide-default-footer :loading="loading"
          :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :items="regimes.data" :headers="headers" height="66vh"
          :calculate-widths="true" style="cursor: pointer">
          <template v-slot:[`item.action`]="{ item }">
            <v-row justify="center">
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn icon small v-on="on">
                    <v-icon small @click="editRegime(item, false)">edit</v-icon>
                  </v-btn>
                </template>
                <span>Edit Regime</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn icon small v-on="on" color="blue">
                    <v-icon small @click="(cloneRegimeItem = item), (confirmClone = true)">content_copy</v-icon>
                  </v-btn>
                </template>
                <span>Clone Regime</span>
              </v-tooltip>
            </v-row>
          </template>
          <template v-slot:[`item.code`]="{ item }">
            <v-chip small outlined style="border: none">
              <!-- <v-avatar size="22" left color="blue" v-if="item.steri">
                                S
                            </v-avatar> -->
              {{ item.code }}
            </v-chip>
          </template>
          <template v-slot:[`item.steri`]="{ item }">
            <v-icon v-if="item.steri" color="success">check</v-icon>
            <v-icon v-else color="danger">close</v-icon>
          </template>
          <template v-slot:[`item.isActive`]="{ item }">
            <v-icon v-if="item.isActive" color="success">check</v-icon>
            <v-icon v-else color="danger">close</v-icon>
          </template>
        </v-data-table>
        <v-col cols="12" class="text-center">
          <el-pagination style="color: var(--v-primaryText-base)" :current-page.sync="page" :pager-count="5"
            :page-size.sync="params.limit" :page-sizes="[18, 25, 50, 100]" :layout="!$vuetify.breakpoint.mobile
              ? 'sizes, prev, pager, next, jumper, total'
              : 'prev, pager, next'
              " :total="regimes.total">
          </el-pagination>
        </v-col>
      </v-card-text>
    </v-card>

    <!-- Add / Edit regime dialog -->
    <v-dialog persistent v-model="dialog" max-width="95vw" :fullscreen="$vuetify.breakpoint.mobile">
      <v-card :loading="loadingRegime" dark>
        <v-toolbar flat color="transparent">
          <v-toolbar-title>{{ modalType }} Regime</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn color="red" icon v-if="modalType == 'Edit'" @click="deleteRegime(regime)"
            :loading="deletingRegime"><v-icon>delete</v-icon></v-btn>
          <v-btn icon color="secondary" @click="saveRegime" :loading="loadingRegime"
            :disabled="!regime.code"><v-icon>save</v-icon></v-btn>
          <v-btn plain depressed elevation="0" @click="closeDialog"><v-icon>close</v-icon></v-btn>
        </v-toolbar>
        <v-card-text>
          <v-row>
            <v-col cols="12" sm="4">
              <v-row class="pa-1 pl-0" align="center">
                <v-col cols="6">
                  <v-text-field hide-details outlined dense label="Code" v-model="regime.code" />
                </v-col>
                <v-col cols="6">
                  <v-switch class="mt-0 pt-0" color="blue" hide-details outlined dense label="STERI"
                    v-model="regime.steri" />
                </v-col>
              </v-row>
              <!-- <v-text-field outlined dense label="Legacy Code" v-model="regime.legacyCode" /> -->
              <v-text-field class="my-2" hide-details outlined dense label="Description" v-model="regime.description" />
              <v-text-field class="my-2" hide-details outlined dense label="Load Specification"
                v-model="regime.loadSpecification" />
              <v-textarea class="my-2" hide-details outlined dense label="Recipe Description"
                v-model="regime.recipeDescription" rows="3" />
              <v-row class="my-2">
                <v-col cols="3" class="mr-0 pr-0">
                  <v-text-field hide-details outlined dense label="Max Temp" v-model="regime.maxProductTemp" type="number"
                    step="0.1" suffix="°C" />
                </v-col>
                <v-col cols="3" class="mx-0 px-0">
                  <v-text-field hide-details outlined dense label="Set Temp" v-model="regime.setPointTemp" type="number"
                    step="0.1" suffix="°C" />
                </v-col>
                <v-col cols="3" class="ml-0 pl-0">
                  <v-text-field hide-details outlined dense label="Min Temp" v-model="regime.minProductTemp" type="number"
                    step="0.1" suffix="°C" />
                </v-col>
                <v-col cols="3" class="ml-0 pl-0">
                  <v-text-field v-if="regime.steri" hide-details outlined dense label="Protocol" v-model="regime.protocol"
                    type="number" step="0.1" suffix="°C" />
                </v-col>
              </v-row>
              <v-row>
                <v-autocomplete :items="allVentSettings" v-model="selectedRegimeVents" label="Vent Settings" outlined
                  dense multiple @change="updateSelectedSettings">
                  <template v-slot:selection="data">
                    <v-chip small v-if="selectedRegimeVents.length == 0" v-bind="data.attrs">
                      None selected
                    </v-chip>
                    <v-chip small v-else-if="data.index === 0">{{
                      `${selectedRegimeVents.length} items
                      selected`
                    }}</v-chip>
                  </template>
                </v-autocomplete>
                <v-col cols="12" v-if="regime.steri" class="mt-0 pt-0">
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>
                        Landbase Steri
                      </v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action class="text-center">
                      <v-switch color="blue" v-model="regime.landbaseSteri"></v-switch>
                    </v-list-item-action>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <v-row class="text-center">
                        <v-list-item-content>
                          <v-col>
                            <v-select :items="probeTypes" outlined dense label="Probe Type" v-model="regime.probeType"
                              item-text="label" item-value="value" />
                          </v-col>
                        </v-list-item-content>
                        <v-tooltip top>
                          <template v-slot:activator="{ on }">
                            <v-list-item-action v-on="on">
                              <v-col>
                                <el-input-number class="my-0 pb-6" label="Probe Count" :min="0" :value="regime.probeCount"
                                  size="mini" v-model="regime.probeCount"></el-input-number>
                              </v-col>
                            </v-list-item-action>
                          </template>
                          <span>Probe Count</span>
                        </v-tooltip>
                      </v-row>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
              </v-row>

              <v-subheader style="font-size: 16px">Regime Products
                <v-btn color="primary" icon
                  @click="(productType = 'Regime'), (productModal = true)"><v-icon>add_circle_outline</v-icon></v-btn></v-subheader>
              <v-divider></v-divider>
              <v-list-item v-if="filteredRegimeProducts.length == 0">
                <v-list-item-content class="text-center">
                  <span style="font-size: 12px; color: grey">No products listed</span>
                </v-list-item-content>
              </v-list-item>
              <v-list-item v-for="product in filteredRegimeProducts" :key="product.id">
                <v-list-item-content>
                  <v-list-item-title>{{
                    $Format.capitalizeFirstLetter(product.product.name)
                  }}</v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-btn color="red" v-on="on" icon small @click="deleteProduct(product, 'Regime')"><v-icon
                          small>delete</v-icon></v-btn>
                    </template>
                    <span>Remove Product</span>
                  </v-tooltip>
                </v-list-item-action>
              </v-list-item>

              <v-card flat :loading="loadingRegimeRules">
                <!-- <v-subheader> Regime Rules </v-subheader>
                                    <v-divider></v-divider> -->
                <v-list dense style="max-height: 50vh; overflow-y: auto">
                  <v-list-item-group>
                    <div v-if="regimeRules && regimeRules.length > 0">
                      <draggable v-model="regimeRules" :animation="200" ghost-class="ghost-card" @end="updateRuleSteps">
                        <div v-for="rule in regimeRules" :key="rule.id">
                          <v-list-item :selectable="false" dense :color="rule.noDays ? 'red' : 'success'">
                            <v-list-item-avatar>
                              {{ rule.ruleNo }}
                              <v-icon v-if="rule.noDays" small color="danger">clear</v-icon>
                              <v-icon v-else small color="success">check</v-icon>
                            </v-list-item-avatar>
                            <v-list-item-content>
                              <v-list-item-subtitle v-if="rule.restOfTrip">Rule Duration: Rest of
                                trip</v-list-item-subtitle>
                              <v-list-item-subtitle v-else>Rule Duration:
                                {{ rule.ruleDuration }}
                                Days</v-list-item-subtitle>
                              <v-list-item-subtitle>Set Point:
                                {{ rule.setPoint }}</v-list-item-subtitle>
                              <v-list-item-subtitle>Max Temp:
                                {{ rule.maxTemp }}</v-list-item-subtitle>
                              <v-list-item-subtitle>Min Temp:
                                {{ rule.minTemp }}</v-list-item-subtitle>
                            </v-list-item-content>
                            <v-list-item-action>
                              <v-row justify="center">
                                <v-btn icon @click="editRegimeRule(rule)">
                                  <v-icon small>edit</v-icon>
                                </v-btn>
                                <v-btn icon @click="deleteRegimeRule(rule)">
                                  <v-icon small color="danger">delete</v-icon>
                                </v-btn>
                              </v-row>
                            </v-list-item-action>
                          </v-list-item>
                        </div>
                      </draggable>
                    </div>
                    <div v-else>
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title>No Rules</v-list-item-title>
                          <v-list-item-subtitle>There haven't been any rules created for this
                            Regime.</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </div>
                  </v-list-item-group>
                </v-list>
                <v-divider></v-divider>
                <v-btn rounded block plain depressed elevation="0" @click="addRegimeRule">
                  + Add Rule
                </v-btn>
              </v-card>
            </v-col>
            <v-col cols="12" sm="4">
              <v-list dense>
                <v-subheader style="font-size: 16px">Countries
                  <v-btn color="primary" icon class="ml-2"
                    @click="countryModal = true"><v-icon>add_circle_outline</v-icon></v-btn>
                  <v-spacer></v-spacer>
                  <v-btn small outlined rounded class="mr-1" color="blue" @click="addAllCountries()"
                    :loading="loadingRegime">Add All</v-btn>
                  <v-btn small outlined rounded class="mr-1" color="primary" @click="removeAllCountries()"
                    :loading="loadingRegime">Remove All</v-btn>
                </v-subheader>
                <v-divider></v-divider>
                <v-text-field placeholder="Search" outlined dense clearable rounded
                  v-model="searchRegimeCountry"></v-text-field>
                <v-list dense style="max-height: 50vh; overflow-y: auto">
                  <v-list-item v-if="filterRegimeCountries.length == 0">
                    <v-list-item-content class="text-center">
                      <span style="font-size: 12px; color: grey">No countries found</span>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item v-for="country in filterRegimeCountries" :key="country.id" @click="setCountry(country)"
                    :style="{
                      'border-left':
                        selectedCountry &&
                          selectedCountry.countryCode == country.countryCode
                          ? '4px solid var(--v-primary-base)'
                          : '4px solid transparent',
                    }">
                    <v-list-item-action>
                      <v-avatar size="32" v-if="country.countryCode">
                        <v-img contain :src="`https://cdn.loglive.io/flags/4x3/${country.countryCode.toLowerCase()}.svg`">
                        </v-img>
                      </v-avatar>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>{{
                        getCountryName(country.countryCode)
                      }}</v-list-item-title>
                      <v-list-item-subtitle>
                        {{ getRegionName(country.countryCode) }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-btn @click.stop="deleteCountry(country)" color="primary" text><v-icon>delete</v-icon></v-btn>
                    </v-list-item-action>
                  </v-list-item></v-list>
              </v-list>
            </v-col>
            <v-col cols="12" sm="4">
              <v-card flat style="
                  border-radius: 20px;
                  background-color: var(--v-component-base) !important;
                  min-height: 500px;
                ">
                <v-card-text>
                  <v-list dense v-if="selectedCountry">
                    <v-subheader style="font-size: 16px"><v-avatar size="32" class="mr-2"
                        v-if="selectedCountry.countryCode">
                        <v-img contain
                          :src="`https://cdn.loglive.io/flags/4x3/${selectedCountry.countryCode.toLowerCase()}.svg`">
                        </v-img>
                      </v-avatar>
                      {{ getCountryName(selectedCountry.countryCode) }}
                      <v-spacer></v-spacer>
                      <v-tooltip top v-if="selectedCountry.id">
                        <template v-slot:activator="{ on }">
                          <v-btn icon small v-on="on" color="secondary" @click="saveCountry(selectedCountry)"
                            :loading="savingCountry"><v-icon small>save</v-icon></v-btn>
                        </template>
                        <span>Save Country Settings</span>
                      </v-tooltip>
                      <v-tooltip top>
                        <template v-slot:activator="{ on }">
                          <v-btn icon small v-on="on" color="red" @click="deleteCountry(selectedCountry)"
                            :loading="deletingCountry"><v-icon small>delete</v-icon></v-btn>
                        </template>
                        <span>Remove Country</span>
                      </v-tooltip>
                    </v-subheader>
                    <v-divider></v-divider>
                    <div v-if="regime.steri">
                      <v-text-field hide-details outlined dense label="Protocol" v-model="selectedCountry.protocol"
                        type="number" step="0.1" suffix="°C" />
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title>
                            Landbase Steri
                          </v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-action class="text-center">
                          <v-switch color="blue" v-model="selectedCountry.landbaseSteri"></v-switch>
                        </v-list-item-action>
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-content>
                          <v-row class="text-center">
                            <v-list-item-content>
                              <v-col>
                                <v-select :items="probeTypes" outlined dense label="Probe Type"
                                  v-model="selectedCountry.probeType" item-text="label" item-value="value" />
                              </v-col>
                            </v-list-item-content>
                            <v-tooltip top>
                              <template v-slot:activator="{ on }">
                                <v-list-item-action v-on="on">
                                  <v-col>
                                    <el-input-number class="my-0 pb-6" label="Probe Count" :min="0"
                                      :value="selectedCountry.probeCount" size="mini"
                                      v-model="selectedCountry.probeCount"></el-input-number>
                                  </v-col>
                                </v-list-item-action>
                              </template>
                              <span>Probe Count</span>
                            </v-tooltip>
                          </v-row>
                        </v-list-item-content>
                      </v-list-item>

                      <v-autocomplete :items="allVentSettings" v-model="selectedCountryRegimeVents"
                        label="Specific Vent Settings" outlined dense multiple>
                        <template v-slot:selection="data">
                          <v-chip small v-if="selectedCountryRegimeVents.length == 0" v-bind="data.attrs">
                            None selected
                          </v-chip>
                          <v-chip small v-else-if="data.index === 0">{{
                            `${selectedCountryRegimeVents.length} items selected`
                          }}</v-chip>
                        </template>
                      </v-autocomplete>
                    </div>

                    <v-subheader style="font-size: 16px">Products
                      <v-btn color="primary" icon class="ml-2" @click="
                      (productType = 'Country'), (productModal = true)
                        "><v-icon>add_circle_outline</v-icon></v-btn>
                      <v-spacer></v-spacer>
                      <!-- <v-tooltip top>
                        <template v-slot:activator="{ on }">
                          <v-btn small v-on="on" color="info" icon class="ml-2" @click="bulkCopyCountries = true">
                            <v-icon>
                              content_copy
                            </v-icon>
                          </v-btn>
                        </template>
                        <span>Copy Country Products</span>
                      </v-tooltip> -->

                    </v-subheader>
                    <v-divider></v-divider>
                    <v-list-item v-if="filteredCountryProducts.length == 0">
                      <v-list-item-content class="text-center">
                        <span style="font-size: 12px; color: grey">No products listed</span>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item v-for="product in filteredCountryProducts" :key="product.id">
                      <v-list-item-content>
                        <v-list-item-title>{{
                          $Format.capitalizeFirstLetter(product.product.name)
                        }}</v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-tooltip top>
                          <template v-slot:activator="{ on }">
                            <v-btn color="red" v-on="on" icon small @click="deleteProduct(product, 'Country')"><v-icon
                                small>delete</v-icon></v-btn>
                          </template>
                          <span>Remove Product</span>
                        </v-tooltip>
                      </v-list-item-action>
                    </v-list-item>
                  </v-list>
                  <v-list-item v-else>
                    <v-list-item-content class="text-center">
                      <span style="font-size: 18px; color: grey">Select a country</span>
                    </v-list-item-content>
                  </v-list-item>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Add / Edit regime rules dialog -->
    <v-dialog persistent v-model="rulesDialog" max-width="600px">
      <v-card :loading="savingRule" dark>
        <v-toolbar dark>
          <v-toolbar-title>{{ ruleModalType }} Regime Rule</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text color="secondary" @click="saveRegimeRule"><v-icon>save</v-icon></v-btn>
          <v-btn text @click="closeRuleDialog"><v-icon>close</v-icon></v-btn>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col>
                <v-card outlined>
                  <v-card-subtitle>Rule Settings</v-card-subtitle>
                  <v-container>
                    <v-select :items="regimeRuleTriggers" outlined dense label="Rule Trigger"
                      v-model="regimeRule.ruleTrigger" item-text="label" item-value="value" />
                    <v-text-field outlined dense label="*Rule Start (No Of Days)" v-model="regimeRule.ruleStartOffset"
                      type="number" step="0.1" width="40px" />
                    <v-text-field :disabled="regimeRule.restOfTrip" outlined dense label="*Duration (No Of Days)"
                      v-model="regimeRule.ruleDuration" type="number" step="1" />
                  </v-container>
                </v-card>
              </v-col>
              <v-col>
                <v-card outlined>
                  <v-card-subtitle>Rule Temperatures</v-card-subtitle>
                  <v-container>
                    <v-text-field outlined dense label="Max Temperature" v-model="regimeRule.maxTemp" type="number"
                      step="0.1" width="40px" />
                    <v-text-field outlined dense label="Set Point Temperature" v-model="regimeRule.setPoint" type="number"
                      step="0.1" />
                    <v-text-field outlined dense label="Min Temperature" v-model="regimeRule.minTemp" type="number"
                      step="0.1" />
                  </v-container>
                </v-card>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-textarea :disabled="true" outlined dense label="Recipe Description"
                  v-model="regime.recipeDescription" />
                <span>* "Rule Start" indicates the amount of days before (-5) or
                  after (5) the "Rule Trigger" the rule should start taking
                  effect.</span>
                <v-spacer></v-spacer>
                <span>* "Duration" indicates the amount of days the rule is in
                  effect. Leave this 0 if the rule applies to the rest of the
                  trip.</span>
              </v-col>
            </v-row>
            <!-- <v-row>
                            <v-col>
                                <v-checkbox label="Is this the last rule?" v-model="regimeRule.restOfTrip"></v-checkbox>
                            </v-col>
                        </v-row> -->
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="countryModal" width="600px" persistent :fullscreen="$vuetify.breakpoint.mobile">
      <v-card :loading="loadingCountries">
        <v-toolbar flat>
          <v-toolbar-title> Select Country </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn v-if="selectedRegion" small outlined rounded class="mr-1" color="blue" @click="addAllFiltered()"
            :loading="savingAllFiltered">Add All Filtered</v-btn>
          <v-menu offset-y left :close-on-content-click="true">
            <template v-slot:activator="{ on }">
              <v-chip v-if="selectedRegion" small v-on="on" class="mr-2">
                {{ selectedRegion.name }}
                <v-icon small right>expand_more</v-icon>
              </v-chip>
              <v-chip v-else small v-on="on" class="mr-2">
                Filter Region
                <v-icon small right>expand_more</v-icon>
              </v-chip>
            </template>
            <v-list dense>
              <v-list-item v-for="(item, index) in regions" :key="index" @click="selectedRegion = item">
                <v-list-item-action>
                  <v-chip small>{{ item.code }}</v-chip>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ item.name }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-btn text @click="countryModal = false">X</v-btn>
        </v-toolbar>
        <v-card-text>
          <v-text-field outlined autofocus dense hide-details v-model="searchCountries" clearable placeholder="Search"
            prepend-inner-icon="search"></v-text-field>
          <v-list dense style="max-height: 50vh; overflow-y: auto">
            <v-list-item v-for="country in filterCountries" :key="country.id" @click="addCountry(country)">
              <v-list-item-action>
                <v-avatar size="24" left>
                  <v-img contain v-if="country.iso2"
                    :src="`https://cdn.loglive.io/flags/4x3/${country.iso2.toLowerCase()}.svg`"></v-img>
                </v-avatar>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  {{ country.name }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{ getRegionName(country.iso2) }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item v-if="filterCountries.length == 0">
              <v-list-item-content class="text-center">
                <span style="color: grey; font-size: 12px">No countries found</span>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- 
        <v-dialog v-model="productModal" width="400px" persistent :fullscreen="$vuetify.breakpoint.mobile">
      <v-card :loading="productLoading">
        <v-toolbar flat>
          <v-toolbar-title> Select a Product </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text @click="productModal = false">X</v-btn>
        </v-toolbar>
        <v-card-text>
          <v-select outlined dense hide-details class="mb-2" :items="['Product Group', 'Product', 'Variety']"
            label="Filter Type" v-model="productFilter"></v-select>
          <v-text-field autofocus dense prepend-inner-icon="search" placeholder="Search" outlined clearable hide-details
            v-model="searchProduct"></v-text-field>
          <v-list dense style="max-height: 50vh; overflow-y: auto">
            <v-list-item v-for="product in filterProducts" :key="product.id" @click="selectProduct(product)">
              <v-list-item-content>
                <v-list-item-title>
                  {{ product.name }}
                </v-list-item-title>
                <v-list-item-subtitle style="font-size: 12px" v-if="product.parentProduct">
                  Parent: {{ product.parentProduct.name }}
                </v-list-item-subtitle>
                <v-list-item-subtitle style="font-size: 12px" v-if="product.hsCode">
                  HS Code: {{ product.hsCode.alternateCode }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item v-if="filterProducts.length == 0 && !productLoading">
              <v-list-item-content class="text-center">
                <span style="font-size: 12px; color: grey">No products found</span>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog> -->

    <!-- New product modal -->
    <v-dialog v-model="productModal" max-width="1300px" persistent :fullscreen="$vuetify.breakpoint.mobile">
      <v-card :loading="productLoading">
        <v-toolbar flat>
          <v-toolbar-title> Add Regime Products </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text @click="closeProductModal()">X</v-btn>
        </v-toolbar>
        <v-container>
          <v-row>
            <v-col>
              <v-card outlined max-width="400px">
                <v-card-title>
                  Product Groups
                </v-card-title>
                <v-card-text>
                  <v-text-field class="mb-2" autofocus dense prepend-inner-icon="search" placeholder="Search" outlined
                    clearable hide-details v-model="searchProductGroup"></v-text-field>
                  <v-list dense style="max-height: 50vh; overflow-y: auto">
                    <v-list-item v-for="product in filterProductGroups" :key="product.id">
                      <v-list-item-action>
                        <v-checkbox v-model="product.selected"></v-checkbox>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ product.name }}
                        </v-list-item-title>
                        <v-list-item-subtitle style="font-size: 12px" v-if="product.parentProduct">
                          Parent: {{ product.parentProduct.name }}
                        </v-list-item-subtitle>
                        <v-list-item-subtitle style="font-size: 12px" v-if="product.hsCode">
                          HS Code: {{ product.hsCode.alternateCode }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item v-if="filterProducts.length == 0 && !productLoading">
                      <v-list-item-content class="text-center">
                        <span style="font-size: 12px; color: grey">No products found</span>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-card-text>
                <v-card-actions class="d-flex justify-center align-center">
                  <v-btn @click="addProducts('Group')" text color="primary">Add Product Groups</v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
            <v-col>
              <v-card outlined max-width="400px">
                <v-card-title>
                  Products
                </v-card-title>
                <v-card-text>
                  <v-text-field class="mb-2" autofocus dense prepend-inner-icon="search" placeholder="Search" outlined
                    clearable hide-details v-model="searchProduct"></v-text-field>
                  <v-list dense style="max-height: 50vh; overflow-y: auto">
                    <v-list-item v-for="product in filterProducts" :key="product.id">
                      <v-list-item-action>
                        <v-checkbox v-model="product.selected"></v-checkbox>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ product.name }}
                        </v-list-item-title>
                        <v-list-item-subtitle style="font-size: 12px" v-if="product.parentProduct">
                          Parent: {{ product.parentProduct.name }}
                        </v-list-item-subtitle>
                        <v-list-item-subtitle style="font-size: 12px" v-if="product.hsCode">
                          HS Code: {{ product.hsCode.alternateCode }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item v-if="filterProducts.length == 0 && !productLoading">
                      <v-list-item-content class="text-center">
                        <span style="font-size: 12px; color: grey">No products found</span>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-card-text>
                <v-card-actions class="d-flex justify-center align-center">
                  <v-btn @click="addProducts('Product')" text color="primary">Add Products</v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
            <v-col>
              <v-card outlined max-width="400px">
                <v-card-title>
                  Varieties
                </v-card-title>
                <v-card-text>
                  <v-text-field class="mb-2" autofocus dense prepend-inner-icon="search" placeholder="Search" outlined
                    clearable hide-details v-model="searchVariety"></v-text-field>
                  <v-list dense style="max-height: 50vh; overflow-y: auto">
                    <v-list-item v-for="product in filterVarieties" :key="product.id">
                      <v-list-item-action>
                        <v-checkbox v-model="product.selected"></v-checkbox>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ product.name }}
                        </v-list-item-title>
                        <v-list-item-subtitle style="font-size: 12px" v-if="product.parentProduct">
                          Parent: {{ product.parentProduct.name }}
                        </v-list-item-subtitle>
                        <v-list-item-subtitle style="font-size: 12px" v-if="product.hsCode">
                          HS Code: {{ product.hsCode.alternateCode }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item v-if="filterProducts.length == 0 && !productLoading">
                      <v-list-item-content class="text-center">
                        <span style="font-size: 12px; color: grey">No products found</span>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-card-text>
                <v-card-actions class="d-flex justify-center align-center">
                  <v-btn @click="addProducts('Variety')" text color="primary">Add Varieties</v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>

    <!-- Create file Dialog -->
    <v-dialog v-model="confirmClone" width="400px" persistent :fullscreen="$vuetify.breakpoint.mobile">
      <v-card>
        <v-toolbar dense flat color="transparent">
          <v-spacer></v-spacer>
          <v-btn text @click="(confirmClone = false), (cloneRegimeItem = {})">X</v-btn>
        </v-toolbar>
        <v-card-text>
          <p style="font-size: 14px">
            Are you sure you would like to clone the regime:
            {{ cloneRegimeItem.code }}?
          </p>
          <v-row justify="center" class="mt-5 mb-3">
            <v-btn color="red" class="mx-2" text small @click="(confirmClone = false), (cloneRegimeItem = {})">No</v-btn>
            <v-btn color="success" class="mx-2" text small @click="cloneRegime()" :loading="savingClone">Yes</v-btn>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="bulkCopyCountries" width="600px" persistent :fullscreen="$vuetify.breakpoint.mobile">
      <v-card :loading="loadingCountries">
        <v-toolbar flat>
          <v-toolbar-title> Select Countries </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text @click="copyCountryProducts" color="secondary"><v-icon>save</v-icon> </v-btn>
          <v-btn text @click="bulkCopyCountries = false">X</v-btn>
        </v-toolbar>
        <v-card-text>
          <v-text-field outlined autofocus dense hide-details v-model="searchCountries" clearable placeholder="Search"
            prepend-inner-icon="search"></v-text-field>
          <v-list dense style="max-height: 50vh; overflow-y: auto">
            <v-list-item-group v-model="selectedCountries" multiple active-class="" color="primary">
              <v-list-item v-for="country in filterRegimeCountries" :key="country.id" :style="{
                'border-left':
                  selectedCountry &&
                    selectedCountry.countryCode == country.countryCode
                    ? '4px solid var(--v-primary-base)'
                    : '4px solid transparent',
              }">

                <v-list-item-action>
                  <v-avatar size="32" v-if="country.countryCode">
                    <v-img contain :src="`https://cdn.loglive.io/flags/4x3/${country.countryCode.toLowerCase()}.svg`">
                    </v-img>
                  </v-avatar>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>{{
                    getCountryName(country.countryCode)
                  }}</v-list-item-title>
                  <v-list-item-subtitle>
                    {{ getRegionName(country.countryCode) }}
                  </v-list-item-subtitle>
                </v-list-item-content>
                <!-- <v-list-item-action>
                <v-checkbox :input-value="false"></v-checkbox>
              </v-list-item-action> -->
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
import draggable from "vuedraggable";
export default {
  components: {
    draggable,
  },
  data: () => ({
    probeTypes: [
      { label: 'Onboard Probes', value: 'ONBOARD PROBES' },
      { label: 'Portable Standard Probes', value: 'PORTABLE STANDARD PROBES' },
      { label: 'Portable-Spear Probes', value: 'PORTABLE-SPEAR PROBES' }
    ],
    allVentSettings: [
      "Closed",
      "5",
      "10",
      "15",
      "20",
      "25",
      "28",
      "30",
      "35",
      "40",
      "50",
      "Fully Open",
      "CA",
      "MA",
      "Auto",
    ],
    bulkCopyCountries: false,
    selectedCountries: [],
    cloneRegimeItem: {},
    countries: [],
    countryModal: false,
    confirmClone: false,
    deletingCountry: false,
    deletingRegime: false,
    headers: [
      {
        text: "Action",
        align: "center",
        sortable: false,
        value: "action",
      },
      {
        text: "Regime Code",
        value: "code",
        align: "center",
        sortable: false,
      },
      {
        text: "Description",
        value: "description",
        align: "left",
        sortable: false,
      },
      {
        text: "STERI",
        value: "steri",
        align: "center",
        sortable: false,
      },
      {
        text: "Load Specification",
        value: "loadSpecification",
        align: "center",
        sortable: false,
      },
      {
        text: "Active",
        value: "isActive",
        align: "center",
        sortable: false,
      },
    ],
    regimes: {
      total: 0,
      data: [],
    },
    regimeRules: [],
    loading: false,
    loadingCountries: false,
    loadingRegime: false,
    loadingRegimeRules: false,
    productModal: false,
    productType: null,
    productLoading: false,
    allProducts: {
      total: 0,
      data: [],
    },
    savingRule: false,
    allRulesValid: false,
    page: 1,
    params: {
      limit: 18,
      offset: 0,
      search: null,
      filter: { isActive: [true] },
    },
    productFilter: 'Product',
    searchTimer: undefined,
    sortBy: "code",
    sortDesc: false,
    updateBadge: 0,
    dialog: false,
    rulesDialog: false,
    modalType: "",
    ruleModalType: "",
    regime: {
      code: null,
      description: null,
      loadSpecification: null,
      recipeDescription: null,
      maxProductTemp: 0,
      setPointTemp: 0,
      minProductTemp: 0,
      legacyCode: null,
      regimeVents: [],
      regimeCountries: [],
      regimeProductGroups: [],
      regimeProducts: [],
    },
    regimeRule: {
      ruleNo: null,
      ruleDuration: 0,
      setPoint: 0,
      maxTemp: 0,
      minTemp: 0,
      restOfTrip: false,
      ruleTrigger: "",
      ruleStartOffset: 0,
    },
    regimeRuleTriggers: [
      { label: "Stuff Date", value: "STUFF DATE" },
      { label: "ETD / ATD", value: "ETD / ATD" },
      { label: "Previous Rule", value: "PREVIOUS RULE" },
      { label: "ETA / ATA", value: "ETA / ATA" },
      { label: "Gated In", value: "GATED IN" },
    ],
    regions: [],
    savingAllFiltered: false,
    savingClone: false,
    savingCountry: false,
    searchCountries: null,
    selectedCountry: null,
    selectedCountryRegimeVents: [],
    selectedRegimeVents: [],
    selectedRegime: {},
    searchProductGroup: null,
    searchProduct: null,
    searchVariety: null,
    selectedRegion: null,
    searchRegimeCountry: null,
    productGroups: [],
    products: [],
    varieties: [],
  }),
  mounted() {
    this.getRegimes();
  },
  created() {
    this.getRegions();
    this.getCountries();
    this.getProducts();
  },
  computed: {
    // regimeValid() {
    //   let disabled = true;
    //   disabled = this.allRulesValid;
    //   if (!this.regime.code || this.regime.code == "") {
    //     disabled = true;
    //   }
    //   return disabled;
    // },
    allowedVentSettings() {
      let result = this.selectedRegimeVents;
      // if(this.selectedRegimeVents.length > 0){
      //     result = result.filter(x => this.selectedRegimeVents.includes(x))
      // }
      return result;
    },
    filterCountries() {
      let result = this.countries;
      if (this.selectedRegion) {
        result = result.filter((x) => x.regionCode == this.selectedRegion.code);
      }
      if (this.regime && this.regime.regimeCountries) {
        result = result.filter(
          (x) =>
            !this.regime.regimeCountries.some((y) => y.countryCode == x.iso2)
        );
      }
      if (this.searchCountries) {
        result = result.filter((x) =>
          x.name.toLowerCase().includes(this.searchCountries.toLowerCase())
        );
      }
      return result;
    },
    filteredCountryProducts() {
      let result = [];
      if (this.selectedCountry && this.regime.regimeProducts) {
        result = this.regime.regimeProducts.filter(
          (x) => x.countryCode == this.selectedCountry.countryCode
        );
      }
      if (this.selectedCountry && this.regime.regimeProductGroups) {
        let groups = this.regime.regimeProductGroups.filter(
          (x) => x.countryCode == this.selectedCountry.countryCode
        );
        groups = groups.map((x) => ({
          ...x,
          product: x.productGroup,
          productId: x.productGroupId,
        }));
        result = [...result, ...groups];
      }
      return result;
    },
    filterRegimeCountries() {
      let result = this.regime.regimeCountries ?? [];
      result = result.map((x) => ({
        ...x,
        name: this.getCountryName(x.countryCode),
      }));
      if (this.searchRegimeCountry) {
        result = result.filter(
          (x) =>
            x.name &&
            x.name
              .toLowerCase()
              .includes(this.searchRegimeCountry.toLowerCase())
        );
      }
      result.sort((a, b) =>
        a.countryCode > b.countryCode
          ? 1
          : b.countryCode > a.countryCode
            ? -1
            : 0
      );
      return result;
    },
    filteredRegimeProducts() {
      let result = [];
      if (this.regime.regimeProducts) {
        result = this.regime.regimeProducts.filter((x) => !x.countryCode);
      }
      if (this.regime.regimeProductGroups) {
        let groups = this.regime.regimeProductGroups.filter(
          (x) => !x.countryCode
        );
        groups = groups.map((x) => ({
          ...x,
          product: x.productGroup,
          productId: x.productGroupId,
        }));
        result = [...result, ...groups];
      }
      return result;
    },
    filterProductGroups() {
      let result = this.productGroups;

      if (this.productType === "Regime") {
        let filteredProductIds = this.filteredRegimeProducts.map(p => p.productId);
        result = result.filter((product) => !filteredProductIds.includes(product.id));
      } else if (this.productType === "Country") {
        let filteredProductIds = this.filteredCountryProducts.map(p => p.productId);
        result = result.filter((product) => !filteredProductIds.includes(product.id));
      }

      if (this.searchProductGroup) {
        result = result.filter((x) =>
          x.name.toLowerCase().includes(this.searchProductGroup.toLowerCase())
        );
      }
      return result;
    },
    filterProducts() {
      let result = this.products;

      if (this.productType === "Regime") {
        let filteredProductIds = this.filteredRegimeProducts.map(p => p.productId);
        result = result.filter((product) => !filteredProductIds.includes(product.id));
      } else if (this.productType === "Country") {
        let filteredProductIds = this.filteredCountryProducts.map(p => p.productId);
        result = result.filter((product) => !filteredProductIds.includes(product.id));
      }

      if (this.searchProduct) {
        result = result.filter((x) =>
          x.name.toLowerCase().includes(this.searchProduct.toLowerCase())
        );
      }
      return result;
    },
    filterVarieties() {
      let result = this.varieties;

      if (this.productType === "Regime") {
        let filteredProductIds = this.filteredRegimeProducts.map(p => p.productId);
        result = result.filter((product) => !filteredProductIds.includes(product.id));
      } else if (this.productType === "Country") {
        let filteredProductIds = this.filteredCountryProducts.map(p => p.productId);
        result = result.filter((product) => !filteredProductIds.includes(product.id));
      }

      if (this.searchVariety) {
        result = result.filter((x) =>
          x.name.toLowerCase().includes(this.searchVariety.toLowerCase())
        );
      }
      return result;
    },
  },
  watch: {
    async page(value) {
      this.params.offset = (value - 1) * this.params.limit;
      await this.getRegimes();
    },
    "params.limit": {
      immediate: true,
      async handler() {
        this.page = 1;
        await this.getRegimes();
      },
    },
    "params.search": {
      async handler() {
        this.page = 1;
        await this.getRegimes();
      },
    },
  },
  methods: {
    closeProductModal() {
      this.productModal = false;
      this.products = this.products.map(x => ({ ...x, selected: false }));
      this.productGroups = this.productGroups.map(x => ({ ...x, selected: false }));
      this.varieties = this.varieties.map(x => ({ ...x, selected: false }));
    },
    async copyCountryProducts() {
      console.log(this.selectedCountries);
      console.log(this.selectedCountry);
      console.log(this.filteredCountryProducts)

      for (let i = 0; i < this.selectedCountries.length; i++) {
        let country = this.filterRegimeCountries[this.selectedCountries[i]]
        console.log(country);

      }

      this.selectedCountries = []
      this.bulkCopyCountries = false
    },
    // async deleteCountry(item){

    //         let index = this.regime.regimeCountries.findIndex(x => x.id == item.id)
    //         this.regime.regimeCountries.splice(index, 1)
    //         await this.$API.updateRegimeCountry({
    //             id: item.id,
    //             isActive: false,
    //             isDeleted: true
    //         })
    //         this.selectedCountry = null
    // },
    async cloneRegime() {
      this.savingClone = true;
      let result = await this.$API.createRegimeClone(this.cloneRegimeItem.id);
      this.savingClone = false;
      if (result) {
        this.confirmClone = false;
        this.cloneRegimeItem = {};
        this.editRegime(result, true);
      }
    },
    async deleteProduct(item, type) {
      if (type == 'Regime') {
        let product = this.regime.regimeProducts.findIndex(
          (x) => !x.countryCode && x.productId == item.productId
        );
        this.regime.regimeProducts.splice(product, 1);
        if (item.id) {
          await this.$API.updateRegimeProduct({
            id: item.id,
            isActive: false,
            isDeleted: true,
          });
        }
      } else {
        let product = this.regime.regimeProducts.findIndex(
          (x) => x.countryCode == this.selectedCountry.countryCode && x.productId == item.productId
        );
        this.regime.regimeProducts.splice(product, 1);
        if (item.id) {
          await this.$API.updateRegimeProduct({
            id: item.id,
            isActive: false,
            isDeleted: true,
          });
        }
      }

    },
    getCountryName(code) {
      let country = this.countries.find((x) => x.iso2 == code);
      if (country) {
        return country.name;
      }
      return null;
    },
    async getProducts() {
      this.productLoading = true;
      this.allProducts = await this.$API.getProducts();
      this.productGroups = this.allProducts.data.filter(x => x.type == 'Product Group').map(x => ({ ...x, selected: false }))
      this.products = this.allProducts.data.filter(x => x.type == 'Product').map(x => ({ ...x, selected: false }))
      this.varieties = this.allProducts.data.filter(x => x.type == 'Variety').map(x => ({ ...x, selected: false }))

      this.productLoading = false;
    },
    async getRegions() {
      this.regions = await this.$API.getRegions();
    },
    getRegionName(countryCode) {
      let region = this.regions.find((x) =>
        x.countryRegions.map((y) => y.iso2).includes(countryCode)
      );
      if (region) {
        return region.name;
      }
      return null;
    },
    async getRegimes() {
      if (this.searchTimer) {
        clearTimeout(this.searchTimer);
      }
      this.loading = true;
      this.searchTimer = setTimeout(async () => {
        this.regimes = await this.$API.getRegimes({
          params: this.params,
        });
        this.loading = false;
      }, 500);
    },

    async getRegimeRules() {
      this.loadingRegimeRules = true;
      this.regimeRules = await this.$API.getRegimeRules(this.regime.id);
      this.loadingRegimeRules = false;
    },

    async addRegime() {
      this.modalType = "Add";
      this.dialog = true;
    },

    async addRegimeRule() {
      this.ruleModalType = "Add";
      this.rulesDialog = true;
    },

    async editRegime(item, cloned) {
      this.loadingRegime = true;
      let detail = await this.$API.getRegimeDetail(item.id);
      this.regime = detail;
      if (cloned) {
        delete this.regime.code;
      }
      item = detail;
      this.regime = item;
      if (this.regime.regimeCountries.length > 0) {
        this.setCountry(this.regime.regimeCountries[0]);
      }
      if (this.regime.regimeVents.length > 0) {
        this.selectedRegimeVents = this.regime.regimeVents
          .filter((x) => !x.countryCode)
          .map((x) => x.ventCode);
      }
      this.modalType = "Edit";
      this.dialog = true;
      await this.getRegimeRules();
      this.loadingRegime = false;
    },

    async editRegimeRule(item) {
      this.regimeRule = item;
      this.ruleModalType = "Edit";
      this.rulesDialog = true;
    },
    async getCountries() {
      this.loadingCountries = true;
      this.countries = await this.$API.searchCountriesBasic({});
      this.loadingCountries = false;
    },
    async saveRegime() {
      this.loadingRegime = true;
      let obj = { code: this.regime.code };
      if (this.regime.id) {
        obj.id = this.regime.id;
      }
      let validateRegime = await this.$API.validateRegimeCode(obj);

      if (this.modalType === "Add") {
        if (validateRegime) {
          this.$message.error(
            "Regime code already exists! Duplicates are not allowed"
          );
          this.loadingRegime = false;
        } else {
          let newRegime = await this.$API.createRegime(this.regime);
          this.regime.id = newRegime.id;
          if (this.selectedRegimeVents.length > 0) {
            let regimeVents = this.selectedRegimeVents.map((x) => ({
              regimeId: this.regime.id,
              countryCode: null,
              ventCode: x,
            }));
            this.regime.regimeVents = regimeVents;
            await this.$API.updateRegime(this.regime);
          }
          // Countries
          let all = this.regime.regimeCountries.map((x) => ({
            ...x,
            regimeId: this.regime.id,
          }));
          await this.$API.bulkCreateRegimeCountry(all);

          // Rules
          for (let i = 0; i < this.regimeRules.length; i++) {
            this.regimeRules[i].regimeId = this.regime.id;
            await this.$API.createRegimeRule(this.regimeRules[i]);
          }
        }
      } else if (this.modalType === "Edit") {
        if (validateRegime) {
          this.$message.error(
            "Regime code already exists! Duplicates are not allowed"
          );
          this.loadingRegime = false;
        } else {
          let regimeVents = this.selectedRegimeVents.map((x) => ({
            regimeId: this.regime.id,
            countryCode: null,
            ventCode: x,
          }));
          this.regime.regimeVents = regimeVents;
          await this.$API.updateRegime(this.regime);
          this.regimeRules = await this.$API.bulkUpdateRegimeRulesAdmin(
            this.regimeRules
          );
        }
      }

      this.closeDialog();
      await this.getRegimes();
      this.loadingRegime = false;
    },
    async addAllFiltered() {
      this.loadingCountries = true;

      if (this.modalType === "Add") {
        let all = this.filterCountries.map((x) => ({
          tempId: x.id,
          countryCode: x.iso2,
          countryRegion: x,
          landbaseSteri: false,
          probeCount: 0,
        }));
        this.regime.regimeCountries = all;
      } else if (this.modalType === "Edit") {
        let all = this.filterCountries.map((x) => ({
          regimeId: this.regime.id,
          countryCode: x.iso2,
          countryRegion: x,
          landbaseSteri: false,
          probeCount: 0,
        }));
        let existingCountryCodes = this.regime.regimeCountries.map(
          (rc) => rc.countryCode
        );
        let newCountries = all.filter(
          (country) => !existingCountryCodes.includes(country.countryCode)
        );
        let created = await this.$API.bulkCreateRegimeCountry(newCountries);
        this.regime.regimeCountries = [
          ...this.regime.regimeCountries,
          ...created,
        ];
      }

      if (this.regime.regimeCountries.length == 1) {
        this.setCountry(
          this.regime.regimeCountries[this.regime.regimeCountries.length - 1]
        );
      }

      this.countryModal = false;
      this.searchCountries = null;
      this.selectedRegion = null;
      this.loadingCountries = false;
    },
    async addAllCountries() {
      this.loadingRegime = true;
      if (this.modalType === "Add") {
        let all = this.countries.map((x) => ({
          tempId: x.id,
          countryCode: x.iso2,
          countryRegion: x,
          landbaseSteri: false,
          probeCount: 0,
        }));
        this.regime.regimeCountries = all;
      } else if (this.modalType === "Edit") {
        let all = this.countries.map((x) => ({
          regimeId: this.regime.id,
          countryCode: x.iso2,
          countryRegion: x,
          landbaseSteri: false,
          probeCount: 0,
        }));
        let existingCountryCodes = this.regime.regimeCountries.map(
          (rc) => rc.countryCode
        );
        let newCountries = all.filter(
          (country) => !existingCountryCodes.includes(country.countryCode)
        );
        let created = await this.$API.bulkCreateRegimeCountry(newCountries);
        this.regime.regimeCountries = [
          ...this.regime.regimeCountries,
          ...created,
        ];
      }
      if (this.regime.regimeCountries.length == 1) {
        this.setCountry(
          this.regime.regimeCountries[this.regime.regimeCountries.length - 1]
        );
      }
      this.countryModal = false;
      this.searchCountries = null;
      this.selectedRegion = null;
      this.loadingRegime = false;
    },
    async removeAllCountries() {
      this.deletingCountry = true;
      this.loadingRegime = true;
      if (this.modalType === "Edit") {
        let all = this.regime.regimeCountries.map((x) => ({
          ...x,
          isDeleted: true,
          isActive: false,
        }));
        await this.$API.bulkCreateRegimeCountry(all);
      }
      this.regime.regimeCountries = [];
      this.countryModal = false;
      this.searchCountries = null;
      this.selectedRegion = null;
      this.loadingRegime = false;
      this.selectedCountry = null;
      this.deletingCountry = false;
    },
    async deleteCountry(item) {
      this.deletingCountry = true;
      if (this.modalType === "Add") {
        let index = this.regime.regimeCountries.findIndex(
          (x) => x.tempId == item.tempId
        );
        this.regime.regimeCountries.splice(index, 1);
      } else if (this.modalType === "Edit") {
        let index = this.regime.regimeCountries.findIndex(
          (x) => x.id == item.id
        );
        this.regime.regimeCountries.splice(index, 1);
        await this.$API.updateRegimeCountry({
          id: item.id,
          isActive: false,
          isDeleted: true,
        });
      }
      if (this.regime.regimeCountries.length == 1) {
        this.setCountry(
          this.regime.regimeCountries[this.regime.regimeCountries.length - 1]
        );
      } else {
        this.selectedCountry = null;
      }
      this.deletingCountry = false;
      this.$message.success("Successfully removed country!");
    },
    async addCountry(country) {
      this.loadingCountries = true;
      if (this.modalType === "Add") {
        let obj = {
          tempId: country.id,
          countryCode: country.iso2,
          countryRegion: country,
          landbaseSteri: false,
          probeCount: 0,
        };
        this.regime.regimeCountries.push(obj);
        if (this.regime.regimeCountries.length == 1) {
          this.setCountry(obj);
        }
      } else if (this.modalType === "Edit") {
        let obj = {
          regimeId: this.regime.id,
          countryCode: country.iso2,
        };
        let result = await this.$API.createRegimeCountry(obj);
        result.countryRegion = country;
        this.regime.regimeCountries.push(result);
        if (this.regime.regimeCountries.length == 1) {
          this.setCountry(result);
        }
      }

      this.$message.success("Successfully added country!");
      this.countryModal = false;
      this.searchCountries = null;
      this.loadingCountries = false;
    },
    setCountry(country) {
      this.selectedCountryRegimeVents = [];
      if (this.regime.regimeVents.length > 0) {
        this.selectedCountryRegimeVents = this.regime.regimeVents
          .filter((x) => x.countryCode == country.countryCode)
          .map((x) => x.ventCode);
      }
      this.selectedCountry = country;
    },
    async saveRegimeRule() {
      this.savingRule = true;
      if (this.regimeRules.length <= 1) {
        this.regimeRule.restOfTrip = true;
      }
      if (this.modalType === "Add") {
        if (this.ruleModalType === "Add") {
          this.regimeRule.ruleNo = this.regimeRules.length + 1;
          this.regimeRules.push(this.regimeRule);
        } else if (this.ruleModalType === "Edit") {
          this.regimeRules[
            this.regimeRules.findIndex((x) => x.id == this.regimeRule.id)
          ] = this.regimeRule;
        }
      } else if (this.modalType === "Edit") {
        if (this.ruleModalType === "Add") {
          this.regimeRule.regimeId = this.regime.id;
          this.regimeRule.ruleNo = this.regimeRules.length + 1;
          this.regimeRule = await this.$API.createRegimeRule(this.regimeRule);
          this.regimeRules.push(this.regimeRule);
        } else if (this.ruleModalType === "Edit") {
          this.regimeRule = await this.$API.updateRegimeRule(this.regimeRule);
          this.regimeRules[
            this.regimeRules.findIndex((x) => x.id == this.regimeRule.id)
          ] = this.regimeRule;
        }
      }
      this.updateRuleSteps();
      this.closeRuleDialog();
      this.savingRule = false;
    },
    async selectProduct(product) {
      this.productLoading = true;
      if (this.productType == "Country") {
        let productIncluded = this.filteredCountryProducts
          .filter((x) => x.productId)
          .map((x) => x.productId)
          .includes(product.id);
        // let productGroups = this.filteredCountryProducts.filter(x=>x.productGroupId).map(x=>x.productGroupId).includes(product.id)
        if (!productIncluded) {
          let obj = {
            type: this.productFilter,
            regimeId: this.regime.id,
            productId: product.id,
            productCode: product.code,
            countryCode: this.selectedCountry.countryCode,
          };
          if (this.regime.id) {
            let result = await this.$API.createRegimeProduct(obj);
            result.product = product;
            if (!this.regime.regimeProducts) {
              this.regime.regimeProducts = [];
            }
            this.regime.regimeProducts.push(result);
          } else {
            let result = obj
            result.product = product;
            if (!this.regime.regimeProducts) {
              this.regime.regimeProducts = [];
            }
            this.regime.regimeProducts.push(result);
          }

          this.productLoading = false;
          this.productModal = false;
        }
      } else {
        // console.log("filteredRegimeProducts", this.filteredRegimeProducts);
        let productIncluded = this.filteredRegimeProducts
          .filter((x) => x.productId)
          .map((x) => x.productId)
          .includes(product.id);
        // let productGroups = this.filteredCountryProducts.filter(x=>x.productGroupId).map(x=>x.productGroupId).includes(product.id)
        if (!productIncluded) {
          // console.log("regime", this.regime);
          let obj = {
            type: this.productFilter,
            regimeId: this.regime.id,
            productId: product.id,
            productCode: product.code,
            countryCode: null,
          };
          let result = obj
          if (this.regime.id) {
            result = await this.$API.createRegimeProduct(obj);
          }
          result.product = product;
          if (this.regime.regimeProducts) {
            this.regime.regimeProducts.push(result);
          } else {
            this.regime.regimeProducts = [result]
            // this.$set(this.regime, "regimeProducts", [result]); // Use this.$set for reactivity
          }
          this.productLoading = false;
          this.productModal = false;
        }
      }
    },

    async addProducts(type) {
      this.productLoading = true;
      let selectedItems = []
      let objs = []

      if (type === 'Variety') {
        selectedItems = [...this.varieties].filter(x => x.selected);
      } else if (type === 'Product') {
        selectedItems = [...this.products].filter(x => x.selected);
      } else if (type === 'Group') {
        selectedItems = [...this.productGroups].filter(x => x.selected);
      }

      if (this.productType == "Country") {
        objs = selectedItems.map((x) => ({
          type: this.productFilter,
          regimeId: this.regime.id,
          productId: x.id,
          productCode: x.code,
          countryCode: this.selectedCountry.countryCode,
        }))

      } else {
        objs = selectedItems.map((x) => ({
          type: this.productFilter,
          regimeId: this.regime.id,
          productId: x.id,
          productCode: x.code,
          countryCode: null,
        }))
      }

      let result = await this.$API.createRegimeProducts(objs);
      if (!this.regime.regimeProducts) {
        this.regime.regimeProducts = [];
      }

      let productMap = new Map(selectedItems.map(item => [item.code, item]));
      let mergedResults = result.map(resItem => {
        let selectedItem = productMap.get(resItem.productCode);
        return { ...resItem, product: selectedItem };
      });

      console.log(selectedItems);
      console.log(result);
      console.log(mergedResults);

      this.regime.regimeProducts = [...this.regime.regimeProducts, ...mergedResults];
      this.productLoading = false;
    },

    updateRuleSteps() {
      if (this.regimeRules.length > 1) {
        for (let i = 0; i < this.regimeRules.length; i++) {
          this.regimeRules[i].ruleNo = i + 1;
          if (this.regimeRules[i].restOfTrip) {
            this.regimeRules[i].restOfTrip = false;
          }

          if (this.regimeRules[i].ruleDuration === 0) {
            this.regimeRules[i].noDays = true;
          }
        }
        this.regimeRules[this.regimeRules.length - 1].restOfTrip = true;
        this.regimeRules[this.regimeRules.length - 1].noDays = false;
        this.loadingRegime = false;
        this.allRulesValid = this.checkRules();
      }
    },

    async deleteRegime(item) {
      this.$confirm("Are you sure you want to delete this Regime?", "Confirm", {
        center: true,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        type: "plain",
      })
        .then(async () => {
          this.deletingRegime = true;
          await this.$API.updateRegime({
            id: item.id,
            isActive: false,
            isDeleted: true,
          });
          this.getRegimes();
          this.dialog = false;
          this.$message({
            type: "success",
            message: "Successfully deleted!",
          });
          this.deletingRegime = false;
        })
        .catch(() => {
          this.deletingRegime = false;

          this.$message({
            type: "error",
            message: "Delete canceled",
          });
        });
    },

    async deleteRegimeRule(item) {
      this.$confirm(
        "Are you sure you want to delete this Regime Rule?",
        "Confirm",
        {
          center: true,
          confirmButtonText: "Yes",
          cancelButtonText: "No",
          type: "plain",
        }
      )
        .then(async () => {
          this.loadingRegime = true;
          if (this.modalType === "Edit") {
            await this.$API.updateRegimeRule({
              id: item.id,
              isActive: false,
              isDeleted: true,
            });
            this.regimeRules = this.regimeRules.filter((x) => {
              return x.id != item.id;
            });
          } else {
            this.regimeRules = this.regimeRules.filter((x) => x !== item);
          }
          this.updateRuleSteps();
          this.$message({
            type: "success",
            message: "Successfully deleted!",
          });
          this.loadingRegime = false;
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "Delete canceled",
          });
          this.loadingRegime = false;
        });
    },

    closeDialog() {
      this.dialog = false;
      this.resetRegime();
      this.resetRegimeRule();
    },

    closeRuleDialog() {
      this.rulesDialog = false;
      this.resetRegimeRule();
    },

    resetRegime() {
      this.regime = {
        code: null,
        description: null,
        loadSpecification: null,
        recipeDescription: null,
        maxProductTemp: 0,
        setPointTemp: 0,
        minProductTemp: 0,
        legacyCode: null,
        regimeVents: [],
        regimeCountries: [],
        regimeProductGroups: [],
        regimeProducts: [],
      };
      this.selectedRegimeVents = [];
      this.regimeRules = [];
      this.selectedCountry = null;
    },

    resetRegimeRule() {
      this.regimeRule = {
        ruleNo: null,
        ruleDuration: 0,
        setPoint: 0,
        maxTemp: 0,
        minTemp: 0,
        restOfTrip: false,
        ruleTrigger: "",
        ruleStartOffset: 0,
      };
    },
    async saveCountry(country) {
      this.savingCountry = true;
      let regimeVents = this.selectedCountryRegimeVents.map((x) => ({
        regimeId: this.regime.id,
        countryCode: country.countryCode,
        ventCode: x,
      }));
      await this.$API.updateRegimeCountry({
        ...country,
        id: country.id,
        regimeVents,
      });
      this.$message.success(
        "Successfully updated settings for " +
        this.getCountryName(country.countryCode) +
        "!"
      );
      this.savingCountry = false;
    },
    checkRules() {
      return !this.regimeRules.every((item) => !item.noDays);
    },

    activeFilters() {
      let keys = Object.keys(this.params.filter);
      let count = 0;
      for (let i = 0; i < keys.length; i++) {
        if (this.params.filter[keys[i]] && keys[i] != "isActive") {
          count++;
        }
      }
      return count;
    },
    updateSelectedSettings() {
      this.selectedCountryRegimeVents = this.selectedCountryRegimeVents.filter(
        (x) => this.selectedRegimeVents.includes(x)
      );
    },
  },
};
</script>

<style scoped>
.ghost-card {
  opacity: 0.5;
  background: #f7fafc;
  border: 1px solid #4299e1;
}

.scroll {
  overflow-y: scroll;
}
</style>